import React, { useState, useEffect } from "react";
import SectionContainer from "../components/SectionContainer";
import { config } from "config";
import { readObject } from "../utils/objects";
import { useApplicationContext } from "../state";
import { clearCampaignData, clearUtmData } from "../utils/campaign-handler";
import ConfigRenderer from "../composite/ConfigRenderer";

const SuccessStepTemplate = ({ pageContext }) => {
    const context = useApplicationContext();
    const {
        stepsData,
        clearContext,
    } = context;
    const stepName = pageContext.step;
    const [{
        inputs,
        nextStep,
    }] = useState(() => ({
        ...readObject(config.steps[stepName]),
    }));

    useEffect(() => {
        let lastStepIndex = stepsData.length - 1;
        if (stepName === "success" && stepsData[lastStepIndex] === "success"){
            clearContext();
            clearCampaignData();
            clearUtmData();
        }
    }, [clearContext, stepName, stepsData]);

    return (
        <SectionContainer>
            <ConfigRenderer
                stepName={stepName}
                context={context}
                step={config.steps[stepName]}
                inputs={inputs}
                nextStep={nextStep}
                // onSubmit={}
            />
        </SectionContainer>
    );
};

export default SuccessStepTemplate;